export default [
  {
    title: 'Статистика',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Сводная таблица',
        route: 'summary-stat',
        icon: 'GridIcon',
      },
      {
        title: 'Сводная по дням',
        route: 'summary-stat-custom',
        icon: 'GridIcon',
      },
      {
        title: 'Сравнение',
        route: 'summary-stat-compare',
        icon: 'GridIcon',
      },
      {
        title: 'Таблица по кампаниям',
        route: 'dashboard-analytics',
        icon: 'GridIcon',
      },
      {
        title: 'Отчет по аудитории',
        route: 'audience-report',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'Правила',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Таблица правил',
        route: 'rules',
        icon: 'GridIcon',
      },
      {
        title: 'Избранные кампании',
        route: 'favorite-comps',
        icon: 'StarIcon',
      },
    ],
  },
  {
    title: 'Рекомендации',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Настройка рекомендаций',
        route: 'recommendations-settings',
        icon: 'SettingsIcon',
      },
      {
        title: 'Таблица рекомендаций',
        route: 'recommendations-table',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'Проверка ссылок',
    icon: 'LinkIcon',
    children: [
      {
        title: 'Проверка на 404',
        route: 'error-links',
        icon: 'GridIcon',
      },
    ],
  },
  {
    title: 'План-факт',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'План-факт',
        route: 'plan-fact',
        icon: 'GridIcon',
      },
    ],
  },
]
