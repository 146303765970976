export default [
  {
    header: 'Правила',
    icon: 'FilePlusIcon',
    children: [
      {
        title: 'Таблица правил',
        route: 'rules',
        icon: 'GridIcon',
      },
      {
        title: 'Избранные кампании',
        route: 'favorite-comps',
        icon: 'StarIcon',
      },
    ],
  },
]
