export default [
  {
    header: 'Рекомендации',
    icon: 'CheckCircleIcon',
    children: [
      {
        title: 'Настройка рекомендаций',
        route: 'recommendations-settings',
        icon: 'SettingsIcon',
      },
      {
        title: 'Таблица рекомендаций',
        route: 'recommendations-table',
        icon: 'GridIcon',
      },
    ],
  },
]
