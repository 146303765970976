<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'projects'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="ListIcon"
        class="mr-50"
      />
      <span>Проекты</span>
    </b-dropdown-item>
    <b-dropdown-divider />

<!--    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Настройки</span>
    </b-dropdown-item>-->
    <b-dropdown-item
      :to="{ name: 'pages-faq' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQ</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Выйти</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import Vue from 'vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // set empty params
      const logoutParams = new URLSearchParams({})

      // logout api
      this.$http.post('auth/logout', logoutParams, {
        headers:
            {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
      })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
        })

      this.$store.commit('project/CLEAR_ACTIVE_PROJECT_ID')
      this.$store.commit('statistic/CLEAR_GRAPH_DATA')
      this.$store.commit('statistic/CLEAR_TABLE_DATA')
      this.$store.commit('statistic/CLEAR_RECOMMENDATION_DATA')
      this.$store.commit('statistic/CLEAR_EFFICIENCY_DATA')
      this.$store.commit('statistic/CLEAR_KPI')
      this.$store.commit('statistic/CLEAR_SETTING_REC')

      Vue.prototype.stopSignalR()

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
