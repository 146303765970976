<template>
  <layout-horizontal>
      <router-view />

   <!-- <app-customizer
        v-if="showCustomizer"
        slot="customizer"
    />-->

  </layout-horizontal>
</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import {
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    LayoutHorizontal,
    BOverlay,
    AppCustomizer,
  },
  data() {
    return {
      showCustomizer: true,
      isChangedProject: false,
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
  },
  watch: {
    isChanged() {
    },
  },
}
</script>
