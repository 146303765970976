export default [
  {
    header: 'Статистика',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Сводная таблица',
        route: 'dashboard-analytics',
        icon: 'GridIcon',
      },
    ],
  },
]
